<template>
    <!-- <div class="content-box">
		<div class="container">
			<div class="search">
				<div class="a-inline-block">
                    <label for="">姓名</label><a-input size="large" placeholder="" />
                </div>
                <div class="a-inline-block">
                    <label for="">性别</label><a-input size="large" placeholder="" />
                </div>
				<div class="a-inline-block">
                    <label for="">部门</label>
                    
                    <a-select size="large" default-value="产品部" >
                        <a-select-option value="产品部">产品部</a-select-option>
                        <a-select-option value="技术部">技术部</a-select-option>
                    </a-select>
                </div>
                <div class="a-inline-block">
                    <a-button size="large" type="primary">搜索</a-button>
                    <a-button size="large">重置</a-button>
                </div>
                <div id="container">
                    
                </div>
			</div>
            
			<a-table  :columns="columns" :data-source="data" class="table">
				<a slot="name" slot-scope="text">{{ text }}</a>
			</a-table>
		</div>
    </div> -->
    <ww-open-data type="userName" openid="RenShengShiChangXiuHang"></ww-open-data>
</template>
<script>
// import $ from "jquery";
import {requestXml} from '../../assets/js/request';
let wx = window.wx;
let WWOpenData = window.WWOpenData
const columns = [
    { title: '姓名', dataIndex: 'name', key: 'name', fixed: 'left' },
    { title: '性别', dataIndex: 'gender', key: '1' },
    { title: '部门', dataIndex: 'department', key: '2' },
    {
        title: '操作',
        key: 'operation',
        fixed: 'right',
        width: 100,
        scopedSlots: { customRender: 'action' },
    },
];
const data = [];
// for (let i = 0; i < 100; i++) {
//     data.push({
//         key: i,
//         name: `Edrward ${i}`,
//         gender: `男`,
//         depart: `技术部`,
//         position:  `技术总监`
//     });
// }

export default {
    data() {
        return {
            url: "https://scrm.jctmj.cn/",
            data,
            columns,
            appId: "",
            timestamp: "",
            nonceStr: "",
            signature: "",
            agentid: "",
        };
    },
    mounted: function(){
        // this.getList();
        // this.getConfig();
    },
    methods:{
        getConfig(){
            requestXml("/scrm/Login/getJsSdkConfig","POST",(res) => {
                console.log(res)
                this.appId = res.corpid; // 必填，企业微信的corpID
                this.timestamp= res.timestamp; // 必填，生成签名的时间戳
                this.nonceStr = res.nonceStr; // 必填，生成签名的随机串
                this.signature = res.signature;
                this.agentid = res.agentid;
          
                wx.config({
                    beta: true,// 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                    debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: this.appId, // 必填，企业微信的corpID
                    timestamp: this.timestamp, // 必填，生成签名的时间戳
                    nonceStr: this.nonceStr, // 必填，生成签名的随机串
                    signature: this.signature,// 必填，签名，见 附录-JS-SDK使用权限签名算法
                    jsApiList: [""], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                });
                wx.ready(function(res){
                    console.log("wx.ready",res)
                    console.log(this.appId,this.timestamp,this.nonceStr,this.signature,this.agentid)
                    wx.agentConfig({
                        corpid: this.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
                        agentid: this.agentid, // 必填，企业微信的应用id （e.g. 1000247）
                        timestamp: this.timestamp, // 必填，生成签名的时间戳
                        nonceStr: this.nonceStr, // 必填，生成签名的随机串
                        signature: this.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
                        jsApiList: [''], //必填，传入需要使用的接口名称
                        success: function(res) {
                            alert(6666)
                            console.log(res)
                            console.info('window.WWOpenData', window.WWOpenData)
                            // 回调
                            WWOpenData.bind(this.$el)

                            wx.invoke('thirdPartyOpenPage', {
                                "oaType": "10001",// String
                                "templateId": "a012ab752540df9a97710ac73e0b87ef_64102059",// String
                                "thirdNo": "thirdNo",// String
                                "extData": {
                                    'fieldList': [{
                                        'title': '采购类型',
                                        'type': 'text',
                                        'value': '市场活动',
                                    },
                                    {
                                        'title': '合同链接',
                                        'type': 'link',
                                        'value': 'https://work.weixin.qq.com',
                                    }],
                                }
                            },
                            function(res) {
                                // 输出接口的回调信息
                                console.log(res);
                            });

                        },
                        fail: function(res) {
                            console.log(res)
                            if(res.errMsg.indexOf('function not exist') > -1){
                                alert('版本过低请升级')
                            }
                        }
                    });
                });
                wx.error(function(res){
                    console.log("wx.error",res)
                });
                

                
            },{"url": location.href.split('#')[0]})
        },
        // getList: function() {
        //     $.ajax({
        //         type: "POST",
        //         url: this.url + "scrm/Staff/getList",
        //         success: function(res) {
        //             // console.log(res.data);
                    
                    
        //             for (let i = 0; i < res.data.length; i++) {
        //                 if(res.data[i].gender == 1){
        //                     res.data[i].gender = "男"
        //                 }else if(res.data[i].gender == 2){
        //                     res.data[i].gender = "女"
        //                 }else{
        //                     res.data[i].gender = "未定义"
        //                 }
        //                 data.push({
        //                     key: i,
        //                     name: res.data[i].name,
        //                     gender: res.data[i].gender,
        //                     department: res.data[i].department
        //                 });
        //             }
                    
        //         }
        //     });
        // }
    },
    computed: {
        
        // rowSelection() {
        //     return {
        //         onChange: (selectedRowKeys, selectedRows) => {
        //             console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        //         },
        //         getCheckboxProps: record => ({
        //             props: {
        //                 disabled: record.name === 'Disabled User', // Column configuration not to be checked
        //                 name: record.name,
        //             },
        //         }),
        //     };
        // },
    },
};
</script>
<style scoped>
    
    
</style>
